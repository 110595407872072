import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "../../../../CommonSCSS/ProductPages.scss";
import { default as Buttons } from "../../../../Component/Common/CustomButton/CustomButton";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import FAQ from "../../../../Component/Desktop/ProductPages/FAQ/FAQ";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import HealthFormContainer from "../../../../Container/Health/Form/HealthFormContainer";
import { useAppSelector } from "../../../../Store/hooks";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import {
  FRONTEND_DOMAIN,
  STRAPI_DOMAIN,
} from "../../../../URLCollection/Domain";
import "./../../../../CommonSCSS/Forms.scss";

function HealthInsurance() {
  const isMobile = useIsMobile();
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const HEALTH_INSURANCE = useAppSelector(
    (state) => state.PRODUCT_PAGES.HEALTH_INSURANCE.attributes.data
  );

  return (
    <>
      <Helmet>
        <title>
          Best Health Insurance Plans Online | Top Companies for Family Health
          Insurance | Cashless Coverage Options
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Find the Health Insurance online in India. Compare various health insurance plans to secure comprehensive coverage for your family's health and well-being."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Health Insurance Online, Best Health Insurance Companies, Best Health Insurance For Family, Family Health Insurance, Cashless Health Insurance, Health Insurance Plans For Family	"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bimastreet" />
        <meta
          property="og:title"
          content="Best Health Insurance Plans Online | Top Companies for Family Health
          Insurance | Cashless Coverage Options"
        />
        <meta
          property="og:description"
          content="Find the Health Insurance online in India. Compare various health insurance plans to secure comprehensive coverage for your family's health and well-being."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FRONTEND_DOMAIN}${location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://www.bimastreet.com/images/health_product_icon.svg"
        />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} />
      </Helmet>

      {isMobile ? <MNavbar /> : <Navbar />}

      <Box className="productPagesWrapper">
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h1>
              <img
                alt=""
                src="./images/health_product_icon.svg"
                height="67px"
              />{" "}
              {HEALTH_INSURANCE.title}
            </h1>
            <p
              style={{ opacity: "0.5" }}
              dangerouslySetInnerHTML={{ __html: HEALTH_INSURANCE.desc }}
            />
          </Grid>
        </Grid>

        {/* Header / From */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing columnReverse"
        >
          <Grid lg={6} xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: HEALTH_INSURANCE.primary_info?.title,
              }}
            />
            <p
              style={{ marginBottom: "10px", marginTop: "24px" }}
              dangerouslySetInnerHTML={{
                __html: HEALTH_INSURANCE.primary_info?.desc,
              }}
            />
          </Grid>
          <Grid lg={6} xs={12}>
            {/* <div className="formSection healthbg">
              {pageStatus === "1" ? (
                <>
                  <Grid
                    container
                    // columnSpacing={3}
                    columnSpacing={{ xs: 2, md: 3 }}
                    sx={{ justifyContent: "center" }}>
                    <Grid
                      xs={12}
                      sx={{
                        textAlign: "center",
                        marginBottom: "16px",
                        paddingTop: "0",
                      }}>
                      <h3>
                        Find the <span className="greenText">Best</span>
                        <span>Health Insurance</span>
                      </h3>
                    </Grid>
                    <Grid md={6} sx={{ maxWidth: "315px" }}>
                      <p className="toinsure">Whom to insure?</p>
                      <ul
                        className="insuredMembers"
                        style={{ marginTop: "8px", marginBottom: "8px" }}>
                        <li
                          onClick={() => {
                            updateMasterState("whom_to_insure", "One Adult");
                          }}
                          className={
                            formData.whom_to_insure === "One Adult"
                              ? "active"
                              : ""
                          }>
                          <span
                            // className={`oneadult active`}
                            className={
                              formData.whom_to_insure === "One Adult"
                                ? "oneadult active"
                                : "oneadult"
                            }></span>
                          One Adult
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("whom_to_insure", "Two Adults");
                          }}
                          className={
                            formData.whom_to_insure === "Two Adults"
                              ? "active"
                              : ""
                          }>
                          <span
                            // className={`twoadults`}
                            className={
                              formData.whom_to_insure === "Two Adults"
                                ? "twoadults active"
                                : "twoadults"
                            }></span>
                          Self+Spouse
                        </li>
                      </ul>
                    </Grid>
                    <Grid
                      md={6}
                      sx={{
                        maxWidth: "315px",
                        paddingLeft: "0",
                        paddingRight: "0",
                      }}>
                      <p className="toinsure">Select Children</p>
                      <ul
                        className="varient_list left_align"
                        style={{ marginTop: "8px" }}>
                        <li
                          onClick={() => {
                            updateMasterState("children", "");
                          }}
                          className={formData.children === "" ? "active" : ""}>
                          0
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("children", "1 Child");
                          }}
                          className={
                            formData.children === "1 Child" ? "active" : ""
                          }>
                          1
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("children", "2 Children");
                          }}
                          className={
                            formData.children === "2 Children" ? "active" : ""
                          }>
                          2
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("children", "3 Children");
                          }}
                          className={
                            formData.children === "3 Children" ? "active" : ""
                          }>
                          3
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("children", "4 Children");
                          }}
                          className={
                            formData.children === "4 Children" ? "active" : ""
                          }>
                          4
                        </li>
                      </ul>
                    </Grid>
                    <Grid xs={12} sx={{ textAlign: "center" }}>
                      <p className="toinsure mb-4">
                        {formData.whom_to_insure === "One Adult" &&
                        formData.children === ""
                          ? "Your Details"
                          : "Eldest Member's Details"}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={{ xs: 2, md: 3 }}
                    rowSpacing={{ xs: 3, md: 0 }}
                    sx={{ justifyContent: "center" }}>
                    <Grid xs={6} sm={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Gender"
                        value={formData.gender.value}
                        attrName={"gender"}
                        value_update={updateMasterState}
                        data={DATA.GENDER}
                        warn_status={formData.gender.warning}
                        error_message={"Select Gender"}
                      />
                    </Grid>
                    <Grid xs={6} sm={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Age"
                        value={formData.age.value}
                        attrName={"age"}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.AGE}
                        warn_status={formData.age.warning}
                        error_message={"Select Age"}
                      />
                    </Grid>
                    <Grid xs={6} sm={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Pincode"}
                        value={formData.pincode.value}
                        attrName={"pincode"}
                        value_update={updateMasterState}
                        warn_status={formData.pincode.warning}
                        max_length={6}
                        validation_type="NUMBER"
                        error_message={
                          isEmpty(formData.pincode.value)
                            ? "Enter pincode"
                            : "Enter valid pincode"
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={3}>
                  <Grid
                    xs={12}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    paddingTop="0px">
                    <Link
                      className="backStep"
                      onClick={() => setPageStatus("1")}
                    />

                    <h3
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginLeft: "-32px",
                      }}>
                      Personal <span>Details</span>
                    </h3>
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Full Name"}
                      value={formData.name.value}
                      attrName={"name"}
                      value_update={updateMasterState}
                      warn_status={formData.name.warning}
                      error_message="Enter Full Name"
                      validation_type="NAME"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Mobile"}
                      value={formData.mobile.value}
                      attrName={"mobile"}
                      value_update={updateMasterState}
                      warn_status={formData.mobile.warning}
                      validation_type="NUMBER"
                      max_length={10}
                      error_message={
                        isEmpty(formData.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid xs={12} className="ctaBtn mt-5" textAlign={"center"}>
                  <CustomButton
                    class_name="greenXlBtn"
                    text_name={pageStatus === "1" ? "Continue" : "View Quotes"}
                    onClickFunction={() => {
                      validate_form();
                    }}
                    loading={showLoader}
                  />
                </Grid>
                {pageStatus === "2" ? (
                  <Grid
                    xs={12}
                    className="disabledCheckbox"
                    textAlign={"center"}>
                    <CustomCheckbox
                      label={
                        <>
                          By clicking on view quotes you agree to all{" "}
                          <a
                            onClick={() =>
                              window.open(
                                COMMON_ROUTES.TERM_AND_CONDTION,
                                "_blank"
                              )
                            }>
                            Terms & Conditions
                          </a>
                          .
                        </>
                      }
                      defaultChecked
                      value={true}
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div> */}
            <Box
              className="formSection"
              sx={{
                ".productForm .formWrapper": {
                  minHeight: "auto",
                  padding: "16px",
                  h6: {
                    fontSize: "24px",
                  },
                },
              }}
            >
              <HealthFormContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Grid container columnSpacing={3} rowSpacing={0}>
                <Grid lg={12} md={12}>
                  <h2
                    style={{ marginBottom: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: HEALTH_INSURANCE.gradient_box_title,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} rowSpacing={0}>
                {HEALTH_INSURANCE?.gradient_data?.map((data) => (
                  <Grid md={6}>
                    <Box className="boderWhitegradientBox">
                      <h5 className="tickTitle">{data.title}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  </Grid>
                ))}
                <Grid xs={12} marginTop={"12px"}>
                  <Box
                    padding={"12px 30px"}
                    sx={{ background: "#ffffff", borderRadius: "50px" }}
                    textAlign={"center"}
                  >
                    <p>{HEALTH_INSURANCE.gradient_note}</p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Box className="inner">
                <img alt="" src="./images/shield-icon.svg" width="58px" />
                <div style={{ flex: "1 0 0" }}>
                  <h4 className="mb-1">{HEALTH_INSURANCE?.buy_quote?.title}</h4>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html: HEALTH_INSURANCE?.buy_quote?.desc,
                    }}
                  />
                </div>
                <div className="ctaBtn">
                  <Buttons
                    onClickFunction={scrollToTop}
                    class_name="greenMdBtn"
                    text_name="Buy Now"
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: HEALTH_INSURANCE?.list_cont_heading?.title,
              }}
            />
            <p style={{ marginBottom: "40px", marginTop: "24px" }}>
              {HEALTH_INSURANCE?.list_cont_heading?.desc}
            </p>
            <ul className="numberList">
              {HEALTH_INSURANCE?.list_cont_data?.map((data, index) => (
                <li>
                  <span>{index + 1}</span>
                  <div>
                    <h4>{data?.title}</h4>
                    <p>{data?.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
            <p style={{ marginTop: "24px" }}>
              {HEALTH_INSURANCE.list_cont_note}
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Grid container columnSpacing={3} rowSpacing={0}>
                <Grid lg={12} md={12}>
                  <h2
                    style={{ marginBottom: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: HEALTH_INSURANCE?.gradient_2_heading?.title,
                    }}
                  ></h2>
                  <p style={{ marginBottom: "24px", marginTop: "24px" }}>
                    {HEALTH_INSURANCE?.gradient_2_heading?.desc}
                  </p>
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} rowSpacing={0}>
                {HEALTH_INSURANCE?.gradint_2_data?.map((data) => (
                  <Grid md={6}>
                    <Box className="boderWhitegradientBox">
                      <h5 className="tickTitle">{data?.title}</h5>
                      <p>{data?.desc}</p>
                    </Box>
                  </Grid>
                ))}
                <Grid xs={12} marginTop={"12px"}>
                  <p>{HEALTH_INSURANCE.gradient_2_note}</p>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* Addon Covers */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h2
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: HEALTH_INSURANCE?.number_box_heading?.title,
              }}
            ></h2>
            <p style={{ marginBottom: "40px", marginTop: "24px" }}>
              {HEALTH_INSURANCE?.number_box_heading?.desc}
            </p>
            <ul className="numberBox">
              {HEALTH_INSURANCE?.number_box_data?.map((data, index) => (
                <li>
                  <div>
                    <p>{index + 1}</p>
                  </div>
                  <h4>{data.title}</h4>
                  <p>{data.desc}</p>
                </li>
              ))}
            </ul>
            <Box
              padding={"12px 30px"}
              sx={{
                background: "rgba(30, 113, 236, 0.04)",
                borderRadius: "50px",
              }}
              textAlign={"center"}
              marginTop={"16px"}
            >
              <p>{HEALTH_INSURANCE.number_box_note}</p>
            </Box>
          </Grid>
        </Grid>

        <Box
          className="greybg sectionSpacing"
          maxWidth="1338px"
          marginLeft="auto"
          marginRight="auto"
          borderRadius="12px"
          padding="24px 12px"
        >
          <Grid container columnSpacing={3} rowSpacing={0}>
            <Grid lg={12} md={12}>
              <h2
                style={{ marginBottom: "24px", textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: HEALTH_INSURANCE?.img_gradient_box_heading?.title,
                }}
              ></h2>
              <p
                style={{
                  marginBottom: "24px",
                  marginTop: "24px",
                  textAlign: "center",
                }}
              >
                {HEALTH_INSURANCE?.img_gradient_box_heading?.desc}
              </p>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} rowSpacing={0}>
            {HEALTH_INSURANCE?.img_gradient_box_data?.map((data) => (
              <Grid md={6}>
                <Box className="whiteGradientBox">
                  <div className="icon">
                    <img
                      src={STRAPI_DOMAIN + data?.image?.data?.attributes?.url}
                      alt=""
                    />
                  </div>
                  <h5>{data?.title}</h5>
                  <p>{data?.desc}</p>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          className="greybg sectionSpacing"
          maxWidth="1338px"
          marginLeft="auto"
          marginRight="auto"
          borderRadius="12px"
          display="flex"
          flexWrap="wrap"
          padding="20px 30px"
          justifyContent="center"
          alignItems="center"
          gap="24px"
          alignSelf="stretch"
        >
          <img src="./images/policy-ponts.svg" width="141px" />
          <p>{HEALTH_INSURANCE.product_info}</p>
        </Box>

        {/* FAQ */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h2 style={{ marginBottom: "18px" }}>
              {HEALTH_INSURANCE?.faq_heading?.title}
            </h2>
            <p style={{ marginBottom: "18px" }}>
              {HEALTH_INSURANCE?.faq_heading?.desc}
            </p>
          </Grid>
          <Grid xs={12}>
            <FAQ data={HEALTH_INSURANCE.faq_data} />
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h4 style={{ marginBottom: "18px" }}>
              {HEALTH_INSURANCE.disclaimer}
            </h4>
          </Grid>
        </Grid>

        <Box
          className="recommendation"
          maxWidth="1338px"
          marginLeft="auto"
          marginRight="auto"
          borderRadius="12px"
          padding="24px 12px"
        >
          <Box className="inner">
            <img alt="" src="./images/idea.svg" width="94px" />
            <div style={{ flex: "1 0 0" }}>
              <p>{HEALTH_INSURANCE.faq_note}</p>
            </div>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
}

export default HealthInsurance;
