import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { useAppSelector } from "../../../Store/hooks";
import "./../../../index.scss";
import "./Footer.scss";
import { useNavigate } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  const navigate = useNavigate();
  const { footer } = useAppSelector((state) => state.Home);
  return (
    <Box className="Footer">
      <Grid container spacing={3} className="row2">
        <Grid xs={12} textAlign={"center"}>
          <img src="./images/logo.svg" alt="bima-street" className="logo" />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="row2 mt-10 mb-8">
        <Grid xs={2}>
          <h5>Company</h5>
          <ul className="footer-nav-list">
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.PRIVACY_POLICY);
                }}
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                }}
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION);
                }}
              >
                Refunds & Cancellation
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.DISCLAIMER);
                }}
              >
                Disclaimer
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.SITEMAP);
                }}
              >
                Sitemap
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={2}>
          <h5>Services</h5>
          <ul className="footer-nav-list">
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.CONTACT_US);
                }}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIMS);
                }}
              >
                Free Claim Assistance
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.CARRIER);
                }}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => {
                  navigate(COMMON_ROUTES.NEED_HELP);
                }}
              >
                Need Help?
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={4}>
          <h5>Contact Details</h5>
          <p className="detail_p mb-3">
            Support mail:{" "}
            <span>
              <a href={`mailto:${footer.support_email}`}>
                {footer.support_email}
              </a>
            </span>
          </p>
          <p className="detail_p mb-3">
            Claim mail:{" "}
            <span>
              <a href={`mailto:${footer.claim_email}`}>{footer.claim_email}</a>
            </span>
          </p>
          <p className="detail_p mb-3">
            For Other Query:{" "}
            <span>
              <a href={`mailto:${footer.for_other_query}`}>
                {footer.for_other_query}
              </a>
            </span>
          </p>
          <p className="detail_p mb-3">
            Phone Number: <span>{footer.phone_number}</span>
          </p>
          {/* <p className="detail_p">
            Toll Free Number: <span>{footer.toll_free_number}</span>
          </p> */}
        </Grid>
        <Grid xs={4}>
          <h5 className="mb-0">
            {footer.company_name}{" "}
            <span style={{ fontWeight: "400" }}>
              is the online insurance platform of
            </span>
          </h5>
          <h5>{footer.product_name}</h5>
          <p className="new_p mb-4">
            <span style={{ color: "#D9312E" }}>Corporate Office :-</span>{" "}
            {footer.address}
          </p>
          <p className="new_p mb-4">
            <span style={{ color: "#D9312E" }}>Regd Office: -</span> Ground
            Floor, Villa No.10, Richmond Villas, Near Glendale International
            School, Hydershakote, Hyderabad-500086.
          </p>

          <p className="new_p mb-4">
            Website Approved By IRDAI(Govt. of india)
            <br />
            <span>ISNP Ref:</span> {footer.isnp_ref}
            <br />
            <span>IRDA License Number:</span> {footer.irda_license_number}
            <br />
            <span>Licensed on: </span>
            {footer.license_on} <br />
            <span>Valid Upto:</span> {footer.valid_upto}
          </p>

          <ul className="socialIcons">
            <li>
              <Link
                href="https://www.youtube.com/channel/UCnou_oz0M7-mxhYbvvOxjPw"
                target="_blank"
              >
                <YouTubeIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.facebook.com/Bimastreet2/"
                target="_blank"
              >
                <FacebookIcon />
              </Link>
            </li>
            <li>
              <Link href="https://x.com/mybimastreet" target="_blank">
                <XIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.instagram.com/_bimastreet?igsh=MWdxYWswczVlczJuMg%3D%3D&utm_source=qr"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.linkedin.com/company/mybimastreet/"
                target="_blank"
              >
                <LinkedInIcon />
              </Link>
            </li>
            {/* <li>
              <Link href="https://linktw.in/qpiOqT" target="_blank">
                <WhatsAppIcon />
              </Link>
            </li> */}
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="row2 mb-8">
        <Grid xs={12}>
          <p className="disclaimer_p">
            <span>Disclaimer:</span> {footer.disclaimer}
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="row2 ">
        <Grid xs={12}>
          <div className="desc_box">
            <p>{`© ${new Date().getFullYear()} ${
              footer.company_name
            } - All Rights Reserved`}</p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
